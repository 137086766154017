<template>
  <div class="notice1">
    <navigationBar :showBack="showBack">{{title}}</navigationBar>
    <img class="banner" :src="dataList.imageUrl" alt="" />
    <notice v-if="showNotice"
        :finished="finished"
        :evaluationId="evaluationId"
      ></notice>
  </div>
</template>

<script type="text/ecmascript-6">
import navigationBar from "@/components/navigationBar/navigationBar";
import notice from "@/components/notice/notice";
import { getEvaluationDetail } from "@/request/api";
export default {
  data() {
    return {
      showBack: true,
      title: "",
      showNotice: true,
      evaluationId: 0,
      finished: 0,
      dataList: {}
    };
  },
  components: {
    navigationBar,
    notice,
  },
  created() {
    this.title = JSON.parse(localStorage.getItem("name"));
    this.finished = this.$route.query.finished;
    this.evaluationId = this.$route.query.evaluationId;
  },
  mounted() {
      this.getEvaluationDetail(this.evaluationId)
  },
  methods: {
    // 测评详情接口
    getEvaluationDetail(id) {
      getEvaluationDetail(id).then((res) => {
        this.dataList = res.data;
      });
    },
  }
};
</script>

<style scoped lang="less">
.notice1 {
  margin-top: 0.88rem;
  min-height: 100vh;
  background: #F3F3F3;

  .banner {
    width: 100%;
    height: 3.52rem;
  }
}
</style>
